import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const [tab, setTab] = useState("");

  const handleTab = (e) => {
    setTab((prevTab) => (prevTab === e ? "" : e));
  };

  return (
    <>
      {/* Main Sidebar Container */}
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <Link to="/dashboard" className="brand-link">
          <img
            src="dist/img/logo.png"
            alt="Logo"
            className="brand-image img-circle "
          />
          <span className="brand-text font-weight-medium">Talentizo</span>
        </Link>

        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li
                className={
                  pathName === "/dashboard" ? "nav-item active" : "nav-item"
                }
              >
                <Link
                  to="/dashboard"
                  className={
                    pathName === "/dashboard" ? "nav-link active" : "nav-link"
                  }
                >
                  <i className="nav-icon far fa-image"></i>
                  <p>Dashboard</p>
                </Link>
              </li>
              <li
                className={
                  pathName === "/skills" ? "nav-item active" : "nav-item"
                }
              >
                <Link
                  to="/skills"
                  className={
                    pathName === "/skills" ? "nav-link active" : "nav-link"
                  }
                >
                  <i className="nav-icon far fa-image"></i>
                  <p>Master Skills</p>
                </Link>
              </li>

              <li
                className={
                  pathName === "/crawling-setting"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link
                  to="/crawling-setting"
                  className={
                    pathName === "/crawling-setting"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className="nav-icon fa fa-robot"></i>
                  <p>Crawling Settings</p>
                </Link>
              </li>

              <li
                className={
                  pathName === "/promotion-code"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link
                  to="/promotion-code"
                  className={
                    pathName === "/promotion-code"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className="nav-icon fa fa-gift"></i>
                  <p>Promotion Code</p>
                </Link>
              </li>

              <li
                className={
                  pathName === "/joinuser" ||
                  pathName === "/pendingrequest" ||
                  tab === "User"
                    ? "nav-item menu-open"
                    : "nav-item"
                }
                onClick={() => handleTab("User")}
              >
                <Link
                  to="#"
                  className={
                    pathName === "/joinuser" || pathName === "/pendingrequest"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className="nav-icon fas fa-user"></i>
                  <p>
                    Users
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </Link>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/joinuser"
                      className={
                        pathName === "/joinuser"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      <i className="nav-icon far fa-circle"></i>
                      <p>All Users</p>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/pendingrequest"
                      className={
                        pathName === "/pendingrequest"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      <i className="nav-icon far fa-circle"></i>
                      <p>Mentors</p>
                    </Link>
                  </li>
                </ul>
              </li>

              <li
                className={
                  pathName === "/sessionbooked" ||
                  pathName === "/upcomingsession" ||
                  pathName === "/rejectedsession" ||
                  tab === "Sessions"
                    ? "nav-item menu-open"
                    : "nav-item"
                }
                onClick={() => handleTab("Sessions")}
              >
                <Link
                  to="#"
                  className={
                    pathName === "/sessionbooked" ||
                    pathName === "/upcomingsession" ||
                    pathName === "/rejectedsession"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className="nav-icon fas fa-eye"></i>
                  <p>
                    Sessions
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </Link>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/sessionbooked"
                      className={
                        pathName === "/sessionbooked"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      <i className="nav-icon far fa-circle"></i>
                      <p>Booked</p>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/upcomingsession"
                      className={
                        pathName === "/upcomingsession"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      <i className="nav-icon far fa-circle"></i>
                      <p>Upcoming</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/rejectedsession"
                      className={
                        pathName === "/rejectedsession"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      <i className="nav-icon far fa-circle"></i>
                      <p>Rejected Sessions</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={pathName === "/group-session" ? "nav-item active" : "nav-item"}
              >
                <Link
                  to="/group-session"
                  className={
                    pathName === "/group-session"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className="nav-icon fas fa-users"></i>

                  <p>Group Session</p>
                </Link>
              </li>

              <li
                className={
                  pathName === "/payables" ||
                  pathName === "/transactions" ||
                  pathName === "/refunds" ||
                  tab === "Report"
                    ? "nav-item menu-open"
                    : "nav-item"
                }
                onClick={() => handleTab("Report")}
              >
                <Link
                  to="#"
                  className={
                    pathName === "/payables" ||
                    pathName === "/transactions" ||
                    pathName === "/refunds"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className="nav-icon fas fa-book"></i>
                  <p>
                    Reports
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </Link>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/payables"
                      className={
                        pathName === "/payables"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      <i className="nav-icon far fa-circle"></i>
                      <p>Payables</p>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/transactions"
                      className={
                        pathName === "/transactions"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      <i className="nav-icon far fa-circle"></i>
                      <p>Transactions</p>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/payments"
                      className={
                        pathName === "/payments"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      <i className="nav-icon far fa-circle"></i>
                      <p>Payments</p>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/refunds"
                      className={
                        pathName === "/refunds" ? "nav-link active" : "nav-link"
                      }
                    >
                      <i className="nav-icon far fa-circle"></i>
                      <p>Refunds</p>
                    </Link>
                  </li>
                </ul>
              </li>

              <li
                className={pathName === "/faq" ? "nav-item active" : "nav-item"}
              >
                <Link
                  to="/faq"
                  className={
                    pathName === "/faq" ? "nav-link active" : "nav-link"
                  }
                >
                  <i className="nav-icon fas fa-question"></i>
                  <p>FAQ</p>
                </Link>
              </li>
              <li
                className={
                  pathName === "/help-center" ? "nav-item active" : "nav-item"
                }
              >
                <Link
                  to="/help-center"
                  className={
                    pathName === "/help-center" ? "nav-link active" : "nav-link"
                  }
                >
                  <i className="nav-icon far fa-question-circle"></i>
                  <p>Help Center</p>
                </Link>
              </li>

              <li
                className={
                  pathName === "/support-new" ? "nav-item active" : "nav-item"
                }
              >
                <Link
                  to="/support-new"
                  className={
                    pathName === "/support-new" ||
                    pathName === "/add-new-case" ||
                    pathName === "/support-new/:case_id"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className="nav-icon fas fa-life-ring"></i>
                  <p>Support</p>
                </Link>
              </li>
              <li
                className={
                  pathName === "/platform-feedback"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link
                  to="/platform-feedback"
                  className={
                    pathName === "/platform-feedback"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className="nav-icon fas fa-comment"></i>
                  <p>Platform Feedback</p>
                </Link>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </>
  );
};
export default Sidebar;
